<template>
  <div class="csn-game">
    <GameLists
      :getList="getGameList"
      :itemsPerScreen="itemsPerScreen"
      :hasVericalUpperRow="true"
    />
  </div>
</template>

<script>
import GameLists from '@/components/GameLists'
import { GameApi } from '@/api'
import { cardsPerScreenCalculationMixin } from '@/mixins'

export default {
  name: 'Game',
  components: {
    GameLists,
  },
  mixins: [cardsPerScreenCalculationMixin],
  methods: {
    getGameList: GameApi.getGameList,
  },
}
</script>
